// client/src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import DeviceList from './components/DeviceList';
import DeviceQC from './components/DeviceQC';
import AddDevice from './components/AddDevice';
import { Container, CssBaseline, AppBar, Toolbar, Typography } from '@mui/material';

function App() {
  return (
    <Router>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component={Link} to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            Device QC App
          </Typography>
        </Toolbar>
      </AppBar>
      <Container>
        <Routes>
          <Route path="/" element={<DeviceList />} />
          <Route path="/device/:serial" element={<DeviceQC />} />
          <Route path="/add-device" element={<AddDevice />} />
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
