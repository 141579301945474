// client/src/components/DeviceQC.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { TextField, Button, Typography, Box, Checkbox, FormControlLabel, Select, MenuItem, InputLabel, FormControl, Snackbar, Grow } from '@mui/material';
import styles from './DeviceQC.css';
const colors = {
    success: '#4caf50',
    error: '#f44336',
    };

const DeviceQC = () => {
  const { serial } = useParams();
  const [device, setDevice] = useState(null);
    const [bundles, setBundles] = useState([]);
    const [selectedBundle, setSelectedBundle] = useState('');
    const [qcItems, setQcItems] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [clientName, setClientName] = useState('');

  useEffect(() => {
    axios.get(`https://kiwi.astroianu.dev/api/bundles`)
      .then(response => {
        setBundles(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the bundles!', error);
        handleSnackbar('There was an error fetching the bundles!', 'error');
      });
  }, []);

  // Listen for changes to the device

  useEffect(() => {
    // Load qc checklists
    axios.get(`https://kiwi.astroianu.dev/api/checklist`)
        .then(response => {
            setQcItems(response.data);
        })
        .catch(error => {
            console.error('There was an error fetching the checklist!', error);
            handleSnackbar('There was an error fetching the checklist!', 'error');
        });
    }, []);
  useEffect(() => {
    if (serial) {
      axios.get(`https://kiwi.astroianu.dev/api/device/${serial}`)
        .then(response => {
          setDevice(response.data);
        })
        .catch(error => {
          console.error('There was an error fetching the device!', error);
          handleSnackbar('There was an error fetching the device!', 'error');
        });
    }
  }, [serial]);

  useEffect(() => {
    if (device) {
      setSelectedBundle(device.bundle);
        setClientName(device.clientName);
    }
  }, [device]);

  // snackbar hooks
    const handleSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };


  const handleCheckboxChange = (index) => {
    const newQC = [...device.qc];
    newQC[index].status = newQC[index].status === true ? false : true;
    setDevice({ ...device, qc: newQC });

    axios.put(`https://kiwi.astroianu.dev/api/device/${serial}`, { ...device, qc: newQC })
        .then(response => {
            handleSnackbar('Device updated successfully!', 'success');
        })
      .catch(error => {
        console.error('There was an error updating the device!', error);
        handleSnackbar('There was an error updating the device!', 'error');
      });
  };

    const handleChangeStatus = () => {
        setDevice({ ...device, ready: device.ready === true ? false : true });
        axios.put(`https://kiwi.astroianu.dev/api/device/${serial}`, { ...device, ready: device.ready === true ? false : true })
        .then(response => {
            handleSnackbar('Device updated successfully!', 'success');
        })
            .catch(error => {
                console.error('There was an error updating the device!', error);
                handleSnackbar('There was an error updating the device!', 'error');
            });
    };


  const handleBundleChange = (e) => {
    setSelectedBundle(e.target.value);
    // Update the device with the new bundle
    axios.put(`https://kiwi.astroianu.dev/api/device/${serial}`, { ...device, bundle: e.target.value })
    .then(response => {
        handleSnackbar('Device updated successfully!', 'success');
    })
      .catch(error => {
        console.error('There was an error updating the device!', error);
        handleSnackbar('There was an error updating the device!', 'error');
      });
  }

  return (
    <Box p={2}>
        <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
        ContentProps={{className: snackbarSeverity === 'success' ? "successSnackbar" : "errorSnackbar"}}
        TransitionComponent={(props) => <Grow {...props} />}
        />
      <Typography variant="h4" gutterBottom>
        QC for Device {device?.serial}
      </Typography>
      <FormControl fullWidth margin="normal">
          <InputLabel>Bundle</InputLabel>
          <Select
            value={selectedBundle}
            onChange={(e) => handleBundleChange(e)}
            required
          >
            {bundles.map((bundle, index) => (
              <MenuItem key={index} value={bundle}>{bundle}</MenuItem>
            ))}
          </Select>
        </FormControl>
                

    {device && (
        <Box mt={2}>
            <TextField
                label="Client Name"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        axios.put(`https://kiwi.astroianu.dev/api/device/${serial}`, { ...device, clientName })
                            .then(response => {
                                handleSnackbar('Device updated successfully!', 'success');
                            })
                            .catch(error => {
                                console.error('There was an error updating the device!', error);
                                handleSnackbar('There was an error updating the device!', 'error');
                            });
                    }
                }}
                fullWidth
                margin="normal"
        
            />
            <FormControlLabel
            className="largeCheckbox"
            control={
                <Checkbox
                checked={device.ready === true}
                onChange={() => handleChangeStatus()}
                color="primary"
                />
            }
            label="Ready"
            />
        </Box>
    )}
      {device && (
        // Show the ready property of the device as a large checkbox
        <Box mt={2}>
          {device.qc.map((item, index) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={item.status === true}
                  onChange={() => handleCheckboxChange(index)}
                  color="primary"
                />
              }
              label={item.item}
              key={index}
            />
          ))}
        </Box>
      )}
    </Box>

  );
};

export default DeviceQC;
