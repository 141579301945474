import { useState } from "react";
import { useZxing } from "react-zxing";

export const BarcodeScanner = (
    {onScan, onError}
) => {
  const [result, setResult] = useState("");
//   const { ref } = useZxing({
//     onDecodeResult(result) {
//       setResult(result.getText());
//         onScan(result);
//     },
//     onDecodeError(error) {
//       onError(error);
//     }
//   });
const {
    ref,
    torch: { status, on, off},
  } = useZxing({
    onDecodeResult(result) {
      setResult(result.getText());
      onScan(result);
    },
    onDecodeError(error) {
      onError(error);
    },
  });

  return (
    <>
      <video ref={ref} />
      {status === "on" || status === "off" ? (
        <button onClick={status === "on" ? off : on}>
          {status === "on" ? "Turn off torch" : "Turn on torch"}
        </button>
      ) : (
        <strong>Unfortunately, torch is not available on this device.</strong>
      )}
      <p>
        <span>Found serial number:</span>
        <span>{result}</span>
      </p>
    </>
  );
};