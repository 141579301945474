// client/src/components/DeviceList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText, ListItemIcon, TextField, Button, Typography, Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
// import QrScanner from 'react-qr-scanner';
import { BarcodeScanner } from './BarcodeScanner';

const DeviceList = () => {
  const [devices, setDevices] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    axios.get(`https://kiwi.astroianu.dev/api/devices`)
      .then(response => {
        setDevices(response.data);
        setFilteredDevices(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the devices!', error);
      });
  }, []);

  const handleScan = (data) => {
    // Try using regex to match the serial number
    // Find text beginning with MOB with the length of 12
    // The string can still have other characters before or after the serial number, so when finding MOB, we need to find the next 12 characters and not more
    const serialRegex = /MOB.{9}/;    
    if (data) {
        const match = data.text.match(serialRegex);
        if (match) {
            const scannedDevice = devices.find(device => device.serial === match[0]);
            if (scannedDevice) {
                window.location.href = `/device/${match[0]}`;
            } else {
                // Create a new device and redirect to the QC page
                window.location.href = `/add-device?serial=${match[0]}`;

            }
        } else {
            console.error('Invalid serial number scanned!');
        }
    }
  };
  

  const handleError = (err) => {
    console.error(err);
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearch(value);
    const filtered = devices.filter(device => device.serial.toLowerCase().includes(value));
    setFilteredDevices(filtered);
  };

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom component={Link} to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
        Device QC App
      </Typography>
      {/* <QrScanner
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: '100%' }}
        facingMode="environment"
      /> */}
        <BarcodeScanner onScan={handleScan} onError={handleError} />
      <TextField
        label="Search Devices"
        value={search}
        onChange={handleSearch}
        fullWidth
        margin="normal"
      />
      <List style={{ maxHeight: '60vh', overflow: 'auto' }}>
        {filteredDevices.map(device => (
          <ListItem button component={Link} to={`/device/${device.serial}`} key={device.serial}>
            <ListItemIcon>
                {device.ready ? <CheckIcon color="primary" /> : <CloseIcon color="error" />}
            </ListItemIcon>
            <ListItemText primary={device.serial} secondary={`${device.bundle} | ${device.clientName}`} />
          </ListItem>
        ))}
      </List>
      <Button variant="contained" color="primary" component={Link} to="/add-device">
        Add Device
      </Button>
    </Box>
  );
};

export default DeviceList;
