// client/src/components/AddDevice.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Box, Checkbox, FormControlLabel, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
// import QrScanner from 'react-qr-scanner';
import { BarcodeScanner } from './BarcodeScanner';
const AddDevice = () => {
  const [serial, setSerial] = useState('');
    const [clientname, setClientName] = useState('');
  const [passQC, setPassQC] = useState(false);
  const [bundles, setBundles] = useState([]);
  const [selectedBundle, setSelectedBundle] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`https://kiwi.astroianu.dev/api/bundles`)
      .then(response => {
        setBundles(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the bundles!', error);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newDevice = { serial: serial, clientName: clientname, bundle: selectedBundle, qc: [] };
    const postBody = {
        serial: serial,
        device: newDevice,
        passQC: passQC,
    }
    axios.post(`https://kiwi.astroianu.dev/api/device`, postBody)
      .then(response => {
        navigate('/');
      })
      .catch(error => {
        console.error('There was an error adding the device!', error);
      });
  };

  const handleScan = (data) => {
    if (data) {
      setSerial(data.text);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };
  
  // Check if the page was navigated with a serial in the URL
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const serial = params.get('serial');
        if (serial) {
        setSerial(serial);
        }
    }, []);

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Add Device
      </Typography>
      {/* <QrScanner
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: '100%' }}
        facingMode="environment"
      /> */}
        {/* Only show the BarcodeScanner if serial is empty or not set */}
        {!serial && <BarcodeScanner onScan={handleScan} onError={handleError} />}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Device Serial"
          value={serial}
          onChange={(e) => setSerial(e.target.value)}
          fullWidth
          required
          margin="normal"
        />
        <TextField
            label="Client Name"
            fullWidth
            margin="normal"
            value={clientname}
            onChange={(e) => setClientName(e.target.value)}
            optional
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Bundle</InputLabel>
          <Select
            value={selectedBundle}
            onChange={(e) => setSelectedBundle(e.target.value)}
            required
          >
            {bundles.map((bundle, index) => (
              <MenuItem key={index} value={bundle}>{bundle}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={passQC}
              onChange={(e) => setPassQC(e.target.checked)}
              color="primary"
            />
          }
          label="Fully pass QC"
        />
        <Button variant="contained" color="primary" type="submit">
          Add Device
        </Button>
      </form>
    </Box>
  );
};

export default AddDevice;
